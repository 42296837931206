@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@400;700&display=swap');

body {
  margin: 0;
  padding: 0;
  font-family: 'Fredoka', sans-serif;
  background-color: #f0f8ff;
}

* {
  box-sizing: border-box;
}

.fade-in {
  animation: fadeIn 1.5s ease-in-out;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.bounce {
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
